import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  FormLabel,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ChatBoxMessagesStyles, textfieldLayout } from "./CustomerBotStyles";
import colors from "../../Constants/colors";

const CustomerBot = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  const [generatingMessage, setGeneratingMessage] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Bayview");
  const [chatHistories, setChatHistories] = useState({});
  const [showSummarizeButton, setShowSummarizeButton] = useState(false);

  const messageEndRef = useRef(null);

  const url = "https://app.queryloop.ai/api/developer/use";
  const verification_token =
    "c2eab1e9b7094c80dd4fe8933512bc0a5d40ee7e98640b5eff4f9c42e0e74f24";
  // "24fe260bb74d464b0e03a463c7cd6e6ea7237369d8f9de48c227bd7b9b8b5beb";
  const summaryApiKey =
    "qlp_gAAAAABnmn6Uwxm7rEjuXItmvsdnurRK-_rA0fl2PLNMkp6IeCNkxtb0xW4C4P2KijlqcSYrQVV7Bt83GDu1ST7dSMtDnpoAKotZzZNO-A0YfnXHEPy2KL8=qlpgAAAAABnmn6UjrklB6hS-VkepMTIr8cnQ0KKnoRkGYR_tkxmT3fQuSa5mt04MbPXoFWriSY1-2l0JedDub1t_qF8MzFF9_mmrwu1l5GaXoNjX2OzIduggR0=";
  // "qlp_gAAAAABnnNEpmA_ZFSsRCBp9TsfLLEJy_auSEwjOg0J8P-aUTyjJDSU9PD9XkQRsbAmuxIA2T2dDIx1K1dhyPFtvcqEECdGZAtGxHWKlEVJ1Qm2zrO_DK2w=qlpgAAAAABnnNEpuy4bfazFwwVH7_-I4-go8eDKR0ZKQguL_ssXSpexsbRwd5oq3_7WQfnxXltu6T1X2oWaZr96OgGvtvXHKBLuL7bG9Np2djvx7NpmxIFvVWI=";

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const apiKeysByName = {
    Bayview:
      "qlp_gAAAAABnm0bH24fyb7dNN2r2-NnfoC3zuzn9PVC1nq4aVKPlTN6p_4jvcCtsTmySpx5JQXOoPxPo4NleIOo4EXrDUU9sKmXVEmEz1X0k_yCOzupwoadnYIo=qlpgAAAAABnm0bHJ4o1MNf1sy9K53YqOQLU6jhMjxotZTH3uKuhqNWybESxx4y7dnIak9mtYKzF_OpA6ijlIbQWEVBVruEqFcCK1-5RXrhS7CWjhZbRqFUwjqE=",
    Chase:
      "qlp_gAAAAABnm0puEYVcN0Jq2ewMh1pb_UMHRYcAC4r4zgGSMXThkOxGKASdB1xdCWiz0fBCj6uMI-rxJVaZpmfLpnlB4NepSC6ux1KEiHeyojuBAZDOupMdn60=qlpgAAAAABnm0pugX6vRzFp-u37MDUwrT3aqdSTVzrrUwl4nYkpzTZfTkZsojCsHWSWn_Uz7Kkjk7gBaN_HECX1OU2LDM9-YsPUWkm4ouWtitKxvxscniLHXkA=",
    "Morgan Stanley":
      "qlp_gAAAAABnm0vlHTKFf3pAcR-X-7kNRRoRpBzmIg2MBOPEfZdJuabJEpG-3qJxRwxu-JI8EfamXOmoDWIR1S4uBajwNtmprEcB2wN_H2ryRPbiPdyH5irrs_g=qlpgAAAAABnm0vlNgl_H2NYn8O9gJEMOcrGrhYkKvQxJSLM7yswvwpL6Gs9247zBUqEaimd_DrvZIkhAqH7APD7zpYapdgh1kD7D7oQNnkIX0HVPoihHcePvoA=",
    Redwood:
      "qlp_gAAAAABnm1CGBsUyfHegnl-wZAXtjVTwCvuU03Fu5rloxWiK7ZlRzT0uI4XZ1j_SA9SwL0OAWe99RPGKg464gr7s0CF4-CfF_oCSbBPNNe2iJq3g0SEYErs=qlpgAAAAABnm1CGelb9QjzQ4ennDoOIhhdKHyIBaXf1e13xM2Kelr8hEZNl5zwv4WXesJhzAxwYmMxGehn_k7TqVgFLWMjVUY5eL4yeNT09FwA_i84paHLLhuI=",
    "SG Capital Partners":
      "qlp_gAAAAABnm1h3YS9ogLDKwqZDR1k4J1cqJ-3RcVPANGbVqMNfZUrilwqOfF9QtFxWQCbuaoDV7K_YH0ltP5cPZtD6V7hfEEPayFOdHyFAi70EMtlzlnvTjCw=qlpgAAAAABnm1h3uX_GQ2LKSS0taQdpKxzt4hK8PYovWmJ_Hi5Vpp7pZoz54K5Bdt3-Y8SMk5wEowLeunTgguyYR8Au8Tfya5oqKMuitSryADGwd71S8pq7yN8=",
    RuraLiving:
      "qlp_gAAAAABnm1ZlO1Pwaasx5y29LuBGM-TzJ6TGkjNXqiEdz-xNpkRiHNd0mqeV_3GaKcrfqg1bIazDflKTRwkXVQcNE2qMPqFG4hl7Z6fVqrpYtx1eo4TtyGM=qlpgAAAAABnm1ZlKu3axGPJxXwf1MW9TZ7cBNlDacuS2nfJN_8b1NyHdMnGPchNOAhIs1Cz6xpUdSsLaRfqsLnTgQCVuQkHZJmKmZtAgwQnUgmULU6kQqAAByU=",
    "Radian Mortgage Capital":
      "qlp_gAAAAABnm1qISFlc9hMSXIBG37_VDVEorrSJmi5A6y8hu9lk0c7zLkISvEDfRqoc90CSiq1x97EOenJY6IJI7fefbAtuie4VhGZPoW17051n0pBlJ_lENf8=qlpgAAAAABnm1qI65xXMg7UTPnwIDkyTmIdv3ralo0HfrsKvHUeYNqRSXv7AVhe7js_YGjHhIRLHy873obIZPBBfFUfTGcb1prboBtesInq-jqoPjVakZnusZQ=",
    "First Citizens Bank":
      "qlp_gAAAAABnm1zeBJUP-dajAihxFsmW2NKHoul4yczopVBQUAkxTs4Cft8wFIvXSt0ZwM9PFm2cwjcvd6tj4M9-ovuhPZHFCy9i9MeHJ2yd1CMCEiuMS1vPoX8=qlpgAAAAABnm1zeDTtcOYhU_bwy52ZId0vxzFIJweXlFGOYy4GmOotzNaKKujXAAnJxr4MUMvxWQZp9eF1aMH7fc-vlG9tyUxH1Nt2dewHR2Eb8B-Q_pzGmC58=",
    Pennymac:
      "qlp_gAAAAABnm17zXTPBO3bytwp2ApG47LNvm5Ch257ARDxU3TgyomFmbP6xZ1GPNd04GaskV8r3L2SdAuIwWoCMdJ4IZWLWXWkjV6B8tNljKZuQVQX5Q3EjsdY=qlpgAAAAABnm17zEQIlvj3Nb_AmoYWRZgPJlSfnF3A_jxqIqdYqxFzKMMhRWo5wW8tGD3PvessTmctTJctrFMpngypg74QYlvQT_nQgf2v7Tjw2T17jVul2JPs=",
    "Onslow Bay":
      "qlp_gAAAAABnqkAmmr3znOIWYRJ-92t5RHx93onUL7S-r3uG-vVdXBvi17Or5fbG-EnavshW06zyaNQWeEeO7i2aecG6DhkU-0VzIABvr1sC8BC4EFLcw_G247U=qlpgAAAAABnqkAmTM50SyhNyFLM7IJj9BIuOMj7kcwXs9FD8qnwbNQpe2emQP1sc22J6iZL-4VyPc1jpvdP2bRSyINMgdl35rJiqJ8ewEydwsJ9UTrzO4OhjOk=",
    eRESI:
      "qlp_gAAAAABnqjxOclEWq9e9So8sw62aU4iOul_3xrBqHNaYsbo97Py7qeBoc7qdoed-A2P0tl2oEt2fOeikegI-6UvS_-TczaPTZdOw79mLj-I2U_U8Ho5nCPU=qlpgAAAAABnqjxOMs_4qWAw6ib-oQ_YNYKC7t_zjCpzI2HAK_G0GCsOtAe_HR2u3b-4_8No54lKpo1Pcm9UVFEeNy0pYNIy3-ZRWacVPcEUMs_JUp-NUtDHGFo=",
    DeepHaven:
      "qlp_gAAAAABnqj0AUI8I7vBPcawPHpOjr0VIvKlIfSwhXCrZHgKU-IAy7tVU_d_yVASksS3yJP_tI0tZcD-Ml-ABcUo3WpF74I-wblvIO8F1IE_ryYLrhvBQhno=qlpgAAAAABnqj0AVmfyOGMG-sdCLgnq5WPHuazTyZjkAwGZUgAE1783WVNCRzdpHiPL-pZ70MCWKaTj5o7Dg7_R-4HTVcQybTy8e1qgyYFDFnc7mFKp-JW-ovg=",
    Verus:
      "qlp_gAAAAABnqkzEH_CwLXAzD0G5Yxhz_nJJHSJE2dtRCHVtioeddeZbnnNgj574-rMqkLYxpJOm_gh9KKJlAw9pUlhCpHIUlyrw5YZMhzFUitEK0IEZCjs9JZA=qlpgAAAAABnqkzEdXbZn1hSrmFyyvF3VJo0s4YZXWf04tw3q0QU-bCR-nXGyXqy6lUmxERSGj7pW5K927Rg2GFi-k3SVAW4tvSZrtVY1cr63YhrfYxLwlw33-8=",
  };

  // Save current messages when switching guidelines

  // const apiKeysByName = {
  //   VERUS:
  //     "qlp_gAAAAABnWH662GH3C85dN5609wFcRiRlQx6g_XdoUk3SEIhyswim7b5gHCBW7DshVXV2TQ59yY1b_n6WduKyKHbtyTBWdw5gPXop7jgM2GKMrHJY58uzQCg=qlpgAAAAABnWH66NCbohKF54UHkZqlZWOrMm9YOEGH0u651NXJGWFztAzsN-6kuJJnj411hd9KpPMlaDmEQqF1kvUY09WB2kX3TnDAkxTtURX77sIqx6rQGf5Y=",
  //   "Onslow Bay":
  //     "qlp_gAAAAABnWH44XPImCpPiA-D5PC_B7Sl3BS3uYKFnxK2bS2628xprMhXrhmVaX8vP_Zvk6ZP0MIPh_BFsZjBaFX1s6QG8BHjd6v6fjEMH-ZykL7zOAEys_ko=qlpgAAAAABnWH44iam5x_NoJLqnqxk4vKpuH-f2kGB1VcJsjN4g9U30hRnGVLl51-NdINoxXB4DElIObz9uvuL857-DbAr9Bh5zNCpuzVZGiD5EP_TxgcY5TGg=",
  //   Deephaven:
  //     "qlp_gAAAAABnWH3av_864oswEvnxoVObyvlfWNQ2XrE6SF4FU6vhTkm2gva_k_o0xoMs6HUBZaGnyKBsHvQz96tni0Q3I95auy98MSmUewqXgnYIHP7aHaD8gzA=qlpgAAAAABnWH3a2cKQXmPICKK5m58PFpf6NpxeKDZrBTCZzqANjlXmKN9J-IDAbzbpu8O9LTQrQ0aWnTe_KhL6hi494pCEIDpey9slljpwlVTvaRV2WwQatnE=",
  //   HomeOwner:
  //     "qlp_gAAAAABnWHwp-UL-MV2YqmHVYeg4XWGJLjfRSHc1dGRcM2O12I_RXNOauV_x8DtIvzTUWQ_P0vXcJaejWy83Ra6ImgX35BNm5-jx_ocHl3_1sJ7YpJIRdls=qlpgAAAAABnWHwpQ7uoB6h1mv4_rDKZ4Ej6GPypnZaoFtH4TVhV3NkzqI6meGDg2f9vCjkgQMv8AcQzImH1pmr7Etq58vQwQ3t6U_EBrFowRf_nkuAWiifwNYA=",
  // };

  const handleOptionChange = async (event) => {
    const newOption = event.target.value;

    // Save the current chat history
    setChatHistories((prevHistories) => ({
      ...prevHistories,
      [selectedOption]: messages,
    }));

    // Load chat history for the new guideline
    setMessages(chatHistories[newOption] || []);
    setSelectedOption(newOption);
  };

  // const handleSnackbarClose = () => {
  //   setSnackbarOpen(false);
  // };

  // Scroll to the bottom whenever messages change

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSummarize = async () => {
    if (!messages.length) return;

    // Extract past user questions for context
    const pastMessages = messages
      .filter((msg) => msg.sender === "user")
      .slice(0, -1)
      .map((msg, index) => `${index + 1}. ${msg.text}`)
      .join("\n\n");

    // Extract the current question (ensure it's from the user)
    const currentQuestion = (() => {
      for (let i = messages.length - 2; i >= 0; i--) {
        // Start from the second last message
        if (messages[i]?.sender === "user") {
          return messages[i]?.text; // User messages are plain strings
        }
      }
      return "No current question provided."; // Fallback if no user message exists
    })();

    // Extract the last bot message (responses from guideline specialists)
    const lastBotMessage = messages[messages.length - 1]?.text;

    // // Extract plain text from ReactMarkdown if needed
    // const botMessageText =
    //   typeof lastBotMessage === "string"
    //     ? lastBotMessage // Use directly if it's a string
    //     : lastBotMessage?.props?.children ||
    //       "Error: Unable to extract bot response.";

    // if (typeof botMessageText !== "string") {
    //   console.error("Bot message is not a string:", botMessageText);
    //   return;
    // }

    // Prepare the summarization payload
    const summarizePayload = {
      query: `Past questions: ${pastMessages}\n\n
Current question: ${currentQuestion}\n\n
Responses from guideline specialists: ${lastBotMessage}`,
      verification_token: verification_token,
    };
    try {
      setGeneratingMessage(true);
      // update the existing last message to indicate summarization is in progress.
      setMessages((prev) => {
        if (!prev.length) return prev;
        const updated = [...prev];
        if (!updated[updated.length - 1].text.includes("(Summarizing...)")) {
          updated[updated.length - 1].text += "\n\n*(Summarizing...)*";
        }
        return updated;
      });
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apiKey: summaryApiKey,
        },
        body: JSON.stringify(summarizePayload),
      });

      if (!response.ok) {
        throw new Error(`Summarization failed: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let summarizedText = "";
      let isFirstChunk = true;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const partial = decoder.decode(value, { stream: true });
        summarizedText += partial;

        setMessages((prev) => {
          if (!prev.length) return prev;
          const updated = [...prev];

          if (isFirstChunk) {
            updated[updated.length - 1].text = updated[
              updated.length - 1
            ].text.replace(/\*\(Summarizing\.\.\.\)\*$/i, "");
            updated[updated.length - 1].text += "\n\n### Summary\n";
            isFirstChunk = false;
          }

          // Append just the new partial text
          updated[updated.length - 1].text += partial;
          return updated;
        });
      }

      setShowSummarizeButton(false); // Hide button after summarization
    } catch (error) {
      console.error("Summarization Error:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Error summarizing response" },
      ]);
    } finally {
      setGeneratingMessage(false);
    }
  };

  // A helper function to parse a single big AI text into lender-specific chunks.
  function parseLenderChunks(aiText) {
    // We'll split on a delimiter you use between responses—often '---\n\n'
    // Adjust the regex/delimiter as needed to match your actual output.
    const sections = aiText.split(/---\s*\n\s*\n/);

    const map = {};
    for (const section of sections) {
      // We look for a pattern like "**VERUS Response:**" or "**Onslow Bay Response:**"
      // capturing the lender name between the asterisks.
      const match = section.match(/\*\*(.+?) Response:\*\*/i);
      if (match) {
        const lender = match[1].trim(); // e.g. "VERUS", "Onslow Bay", "Deephaven", etc.
        map[lender] = section.trim();
      }
    }
    return map;
  }

  // This function creates a specialized memory for a single lender,
  // so that in *each* memory pair, the AI text is replaced with just that lender's chunk.
  function buildSpecializedMemoryForLender(memory, lenderName) {
    // Deep-clone the memory to avoid mutating the original
    const clonedMemory = JSON.parse(JSON.stringify(memory));

    for (let i = 0; i < clonedMemory.messages.length; i++) {
      const pair = clonedMemory.messages[i];
      if (!pair.AI) continue; // skip user-only pairs

      // Parse the multiple-lender text
      const lenderChunks = parseLenderChunks(pair.AI);

      // Replace the full AI text with only the chunk for the given lender
      pair.AI =
        lenderChunks[lenderName] ||
        `No specific text found for ${lenderName} in:\n${pair.AI}`;
    }
    return clonedMemory;
  }

  const botIndexRef = useRef(null);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;
    setNewMessage("");
    setIsLoading(true);
    setGeneratingMessage(true);
    setMessages((prev) => {
      // userIndex = prev.length
      const userIndex = prev.length;
      // botIndex = userIndex + 1
      const botIndex = userIndex + 1;

      botIndexRef.current = botIndex; // store for streaming updates

      return [
        ...prev,
        { sender: "user", text: newMessage },
        { sender: "bot", text: "Generating response..." },
      ];
    });

    // Format messages for the "memory" field
    let memory = {
      messages: [],
    };

    // Pair USER and AI messages and extract combined text
    for (let i = 0; i < messages.length; i++) {
      const current = messages[i];
      const next = messages[i + 1];

      // If the current message is from the user and the next one is from the bot, pair them
      if (current.sender === "user" && next?.sender === "bot") {
        memory.messages.push({
          USER: current.text,
          AI: next.text, // Extract combined text
        });
        i++; // Skip the next message since it's already paired
      }
    }

    console.log("memory", memory);

    // Extract the last 5 message pairs
    memory.messages = memory.messages.slice(-5);

    try {
      if (selectedOption === "eligibility_buddy") {
        const userMessageIndex = messages.length;
        const apiKeys = Object.entries(apiKeysByName);
        const fetchPromises = apiKeys.map(([botName, botKey]) => {
          // Build the specialized memory for *this* lender
          const specializedMemory = buildSpecializedMemoryForLender(
            memory,
            botName
          );

          // Make the payload
          const payload = {
            query: newMessage,
            verification_token,
            memory: specializedMemory,
          };

          return fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              apiKey: botKey,
            },
            body: JSON.stringify(payload),
          });
        });

        const responses = await Promise.all(fetchPromises);
        let botResponses = Array(apiKeys.length).fill(""); // Initialize an empty array for responses

        for (let i = 0; i < apiKeys.length; i++) {
          const reader = responses[i].body.getReader();
          const decoder = new TextDecoder("utf-8");
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            botResponses[i] += decoder.decode(value, { stream: true });

            // Combine all lenders' partial responses into one big Markdown string
            let combinedText = apiKeys
              .map(([name], index) =>
                botResponses[index]
                  ? `**${name} Response:**\n${botResponses[index]}\n\n---\n\n`
                  : ""
              )
              .join("");

            // Update the last message as a string
            setMessages((prev) => {
              const updated = [...prev];
              updated[userMessageIndex + 1].text =
                combinedText || "No responses yet...";
              return updated;
            });
          }
        }
        setShowSummarizeButton(true);
      } else {
        const payload = {
          query: newMessage,
          verification_token: verification_token,
          memory,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            apiKey: apiKeysByName[selectedOption],
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let botMessage = "";
        const botIndex = messages.length;

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          botMessage += decoder.decode(value, { stream: true });

          setMessages((prev) => {
            const updated = [...prev];
            // We stored this index in a ref
            const idx = botIndexRef.current;
            updated[idx].text = botMessage; // partial streaming text
            return updated;
          });
        }
      }
    } catch (error) {
      console.error("Error fetching response:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Error fetching response" },
      ]);
    } finally {
      setIsLoading(false);
      setGeneratingMessage(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100vh",
        px: 8,
        bgcolor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          backgroundColor: colors.extremeBlue,
          mt: "20px",
          boxShadow: `0px 0px 70px ${colors.secondaryBlue}`,
          py: 2,
          px: 4,
          height: "93vh",
          borderRadius: "8px",
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: `1px solid ${colors.extremeWhite}`,
            pb: 2,
            my: 1,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              flex: 1,
              textAlign: "center",
              color: colors.extremeWhite,
            }}
          >
            Chat with Homie IQ
          </Typography>
        </Box>

        <Box sx={ChatBoxMessagesStyles}>
          {(messages || []).map((message, index) => {
            const isUser = message.sender === "user";
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent:
                    message.sender === "user" ? "flex-end" : "flex-start",
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    maxWidth: "70%",
                    padding: "10px 15px",
                    borderRadius: "15px",
                    backgroundColor:
                      message.sender === "user"
                        ? colors.primaryBlue
                        : colors.textFieldBackground,
                    color: colors.extremeWhite,
                    boxShadow: `0px 4px 6px ${colors.secondaryBlue}`,
                  }}
                >
                  {/* Render the message content */}
                  {!isUser && typeof message.text === "string" ? (
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {message.text}
                    </ReactMarkdown>
                  ) : (
                    // Otherwise user text or something else
                    message.text
                  )}

                  {/* Show "Summarize" button below the last lender response */}
                  {index === messages.length - 1 &&
                    showSummarizeButton &&
                    !generatingMessage && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          mt: 1,
                        }}
                      >
                        <button
                          onClick={handleSummarize}
                          style={{
                            padding: "8px 16px",
                            backgroundColor: colors.primaryBlue,
                            color: "white",
                            borderRadius: "8px",
                            border: "none",
                            cursor: "pointer",
                            fontWeight: "bold",
                            boxShadow: `0px 4px 6px ${colors.secondaryBlue}`,
                          }}
                        >
                          Summarize
                        </button>
                      </Box>
                    )}
                </Box>
              </Box>
            );
          })}
          <div ref={messageEndRef} />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            variant="outlined"
            placeholder="Type your message..."
            fullWidth
            sx={textfieldLayout}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={isLoading || generatingMessage}
          />
          <IconButton
            sx={{
              color: colors.extremeWhite,
              backgroundColor: colors.primaryBlue,
              "&:hover": {
                backgroundColor: colors.tertiaryBlue,
              },
              "&.Mui-disabled": {
                backgroundColor: colors.darkGray,
                color: colors.lightGray,
              },
            }}
            onClick={handleSendMessage}
            disabled={isLoading || generatingMessage}
          >
            <ArrowUpwardIcon />
          </IconButton>
        </Box>
        {/* <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="info"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar> */}
      </Box>
      {/* Radio Groups Section */}
      <FormControl sx={{ ml: 4, mt: 2 }}>
        <FormLabel>
          <Typography variant="h6" fontWeight="bold">
            Choose a Guideline
          </Typography>
        </FormLabel>
        <RadioGroup
          value={selectedOption}
          onChange={handleOptionChange}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {Object.keys(apiKeysByName).map((key) => (
            <Tooltip key={key} title={`Guideline: ${key}`} arrow>
              <FormControlLabel
                value={key}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                      maxWidth: "90%",
                    }}
                  >
                    {key}
                  </Typography>
                }
                sx={{ alignItems: "center", ml: 1 }}
              />
            </Tooltip>
          ))}
          <FormLabel>
            <Typography variant="h6" fontWeight="bold">
              Or
            </Typography>
          </FormLabel>
          <Tooltip title="Talk to all Guidelines and summarize responses">
            <FormControlLabel
              value="eligibility_buddy"
              control={<Radio />}
              label="Talk to Eligibility Tool"
              sx={{ alignItems: "center", ml: 1 }}
            />
          </Tooltip>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default CustomerBot;
